<template lang="pug">
v-container(:style="{ margin: isMobile() ? '1rem' : '2rem' }")
  v-row
    v-col(:style="{color: textColorHex, fontSize: '24px' }")
      v-row
      div.d-flex.align-start.text-wrap
        p(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.id+1 }}
        v-icon.mt-1.mx-n2(large :color="textColorHex")(left) mdi-menu-right
        p.mx-2(:style="{color: textColorHex, fontSize: '28px' }") {{ getSlide.question }}{{ getSlide.responseIsRequired? '*' : '' }}
      v-col(v-for="(option, idx) in getSlide.options" :key="idx" class="shrink ml-n2")
        v-chip-group(
          column 
          class="mt-n4"
        )
          v-chip(
            @click="getSlide.answer = option.name, selectAnswer(option.name)"
            label
            outlined
            :color="textColorHex"
            :class="{'active-chip': answer === option.name}"
          ) 
            v-icon(:style="{color: textColorHex, fontSize: '24px' }") {{ optionIcons[idx] }}
            p(:style="{color: textColorHex, fontSize: '24px' }" class="ml-2 mt-4 text-body-1") {{ option.name }}
        v-col(
          class="shrink ml-n2"
          cols="12"
          lg="3"
          md="4"
          sm="7"
        ) 
          v-alert(
            v-if="getErrors[0]"
            class="mb-2 shake"
            dense
            outlined
            type="error") {{getErrors[0]}}
      v-btn(:color="secondaryColorHex" light raised elevation="0" class="font-weight-regular text-none" @click="submit")
        p(:style="{color: buttonTextColorHex }" class="text-body-1 text-none mt-4 font-weight-regular") Submit

      p {{ answer }}
  </template>
  
  <script>
  // THIS IS OLD - TO BE DELETED
  import { mapGetters } from "vuex";
  import axios from 'axios';

  const cheqqMateDNABaseUrl = '/data-api/rest'; //process.env.VUE_APP_CHEQQMATE_DNA_BASE_URL_LOCAL

  export default {
    name: "MultipleChoiceChip",
    data() {  
      return {
        id2: '',
        slide: 'multiple-choice',
        optionIcons: ["mdi-alpha-a-box", "mdi-alpha-b-box", "mdi-alpha-c-box", "mdi-alpha-d-box", "mdi-alpha-e-box"],
        isActive: false,
        answer: '',
        errorMessages: [],
      };
    },
    mounted() {
      if (!this.getSlide.answer) {
        this.$store.commit("ADD_ANSWER_TO_SLIDE", null);
      }
    },
    watch: {
      answer: {
        handler() {
          this.isActive = !this.isActive;
        },
        deep: true
      },
      hasError(e) {
        if (e) {
          this.errorMessages = ['Please select an option.'];
        }
      },
      "getSlide.answer": {
        handler() {
          this.errorMessages = [];
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters(["getSlide"]),
      getErrors() {
        return this.errorMessages;
      },
      textColorHex() {
        return this.$store.state.textColorHex;
      },
      buttonTextColorHex() {
        return this.$store.state.buttonTextColorHex;
      },
      secondaryColorHex() {
        return this.$store.state.secondaryColorHex;
      },
      contactId() {
        return this.$store.state.contactId;
      },
      chipStyle() {
      return this.isActive
        ? {
            backgroundColor: this.darkerColor(this.secondaryColorHex),
            borderColor: this.darkerColor(this.secondaryColorHex),
            borderWidth: '2px',
            borderStyle: 'solid'
          }
        : {};
      }
    },
    methods: {
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true;
        }

        return false;
      },
      submit() {
      this.registerFormTransaction();
    },
    nextSlide() {
      this.$emit("start-slides");
    },
    sqlConvertDate (date) {
      return new Date(date).toISOString().replace('T', ' ').replace('Z', '')
    },
    registerFormTransaction() {
        // Get the current date and time
        const currentDateTime = new Date();

        // Format the date using sqlConvertDate method
        const formattedDate = this.sqlConvertDate(currentDateTime);

        var postData = {
              "FormId": this.$store.state.form.id,
              "ContactId": this.contactId?this.contactId:-1,
              "QuestionId": this.getSlide.id,
              "Answer": this.getSlide.answer,
              "TransactionDate": formattedDate, // Use the formatted date
            }
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:4280'; //process.env.VUE_APP_CHEQQMATE_APP_BASE_URL || '';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.post(`${cheqqMateDNABaseUrl}/formTransaction`,postData).then(() => {
          this.nextSlide();
        })
    },
    darkerColor(color) {
      // Function to make the color darker
      let colorValue = parseInt(color.slice(1), 16);
      colorValue = Math.max(0, colorValue - 0x333333);
      return `#${colorValue.toString(16).padStart(6, '0')}`;
    },
    selectAnswer(answer) {
      this.answer = answer;
    },
  }
  };
  </script>
  
  <style scoped>
  .slide-FirstPage {
    background-color: black;
  }
  .question-number {
    color: #D53E59; /* pink darken-1 */
    font-size: 18px;
  }

  .button {
    /* color: white; */
    font-size: 16px;
    padding: 10px 20px;
  }
  .active-chip {
    border-style: solid;
    border-width: 2px;
    /* make the selection more pronunced */
    font-weight: bold!important;
    
  }
</style>